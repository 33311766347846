/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/react@16.12.0/umd/react.development.js
 * - /npm/react-dom@16.12.0/umd/react-dom.development.js
 * - /npm/@material-ui/core@4.8.3/umd/material-ui.development.js
 * - /npm/@babel/standalone@7.8.8/babel.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
